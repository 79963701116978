import React, { useState } from 'react'

const DemoRequest = ({isDesktop}) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        reasons: {
          webDesign: false,
          mobileAppDesign: false,
          collaboration: false,
          others: false
        },
        message: ''
      })
    
      const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({ ...prev, [name]: value }))
      }
    
      const handleCheckboxChange = (e) => {
        const { name, checked } = e.target
        setFormData(prev => ({
          ...prev,
          reasons: { ...prev.reasons, [name]: checked }
        }))
      }
    
      const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
        // Handle form submission here
      }
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-2xl bg-black bg-opacity-50 p-8 rounded-lg">
        <form onSubmit={handleSubmit} className="space-y-6">
          
          <div className={`grid ${isDesktop?"grid-cols-2":"grid-cols-1"} gap-6`}>
          <div className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="fullName" className="block text-sm font-medium mb-1">Full Name</label>
            <input id="fullName" value={formData.fullName}
                onChange={handleInputChange} type="text" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
          <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
            <input id="email" value={formData.email}
                onChange={handleInputChange} type="email" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
        </div>

          <div>
            <p className="text-white mb-2">Why are you contacting us?</p>
            <div className="grid grid-cols-2 gap-4">
              {[
                { name: 'webDesign', label: 'Web Design' },
                { name: 'mobileAppDesign', label: 'Mobile App Design' },
                { name: 'collaboration', label: 'Collaboration' },
                { name: 'others', label: 'Others' }
              ].map(({ name, label }) => (
                // <label key={name} className="flex items-center space-x-3 text-white">
                //   <input
                //     type="checkbox"
                //     name={name}
                //     checked={formData.reasons[name]}
                //     onChange={handleCheckboxChange}
                //     className="form-checkbox h-5 w-5 text-yellow-500 rounded bg-gray-700 border-gray-600"
                //   />
                //   <span>{label}</span>
                // </label>
                <div key={name} className="flex items-center space-x-2">
                <input checked={formData.reasons[name]}
                    onChange={handleCheckboxChange} type="checkbox" name={name}  id={label.toLowerCase().replace(' ', '-')} className="rounded bg-gray-800 border-gray-700 text-orange-500 focus:ring-orange-500" />
                <label htmlFor={name.toLowerCase().replace(' ', '-')}>{label}</label>
              </div>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="message" className="sr-only">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your Message"
              rows={4}
              className="w-full bg-[#262626] bg-opacity-50 p-2 text-white placeholder-gray-400 border-gray-600 rounded-md p-3"
              required
            ></textarea>
          </div>

          <div className='flex justify-center'>

        <button type="submit" className="w-fit bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        </div>
        </form>

        <div className="mt-8 space-y-4 text-gray-400">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
            <div className="flex items-center space-x-2">
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span>shivbratmishra@photonsoftwares.com</span>
            </div>
            <div className="flex items-center space-x-2">
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              <span>+91 8400063557</span>
            </div>
            <div className="flex items-center space-x-2">
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span>Get Location</span>
            </div>
          </div>
          
          <div className={`flex ${isDesktop?"flex-row": "flex-col"} justify-between items-start md:items-center space-y-2 md:space-y-0 pt-4 border-t border-gray-700`}>
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Operating Days</span>
              <span>Monday to Friday</span>
            </div>
            <div className="flex items-center space-x-4 m-0">
              <span>Stay Connected</span>
              <div className="flex space-x-2">
                <a href="#" className="text-green-500 hover:text-green-400">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" />
                  </svg>
                </a>
                <a href="#" className="text-blue-400 hover:text-blue-300">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
                <a href="#" className="text-blue-600 hover:text-blue-500">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoRequest