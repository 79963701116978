import React from 'react';
import { motion } from 'framer-motion';
import { Box, Grid, Typography, TextField, Checkbox, Button, Container, Slider } from '@mui/material';
import { Row } from 'react-bootstrap';

const variant = {
  initial: { opacity: 0, y: 100 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 1, staggerChildren: 0.1 },
  },
};

function Contactus({ isDesktop }) {
  const [value, setValue] = React.useState([10000, 80000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  return (
<Container  id="contactUs" className={`${isDesktop ? "h-fit" : "h-fit"} overflow-hidden`}>

    <Box className={`lg:${isDesktop ? 'w-3/4' : 'w-full' } `}>
      <motion.div variants={variant} initial="initial" whileInView="animate">
      <div className="w-full flex flex-col items-center justify-center max-w-full">
              <Row className="mt-2 mb-3">
              <Typography
              variant="h3"
              className="text-white font-inherit font-bold "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Contact Us
            </Typography>
              </Row>
              <Row className="mt-2 mb-3">
                <h6 className="m-0 text-center font-bold ">Get in touch with us today and let us help you with any questions or inquiries you may have.</h6>
              </Row>
            </div>
      <div className="min-h-screen bg-black bg-opacity-50 text-gray-100 p-4 md:p-8">
      <form className="max-w-3xl mx-auto space-y-6">
        <div className={`grid ${isDesktop?"grid-cols-2":"grid-cols-1"} gap-6`}>
          <div className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="fullName" className="block text-sm font-medium mb-1">Full Name</label>
            <input id="fullName" type="text" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
          <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
            <input id="email" type="email" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
        </div>

        <div className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label className="block text-sm font-medium mb-2">Why are you contacting us?</label>
          <div className={`grid ${isDesktop?"grid-cols-2": "md:grid-cols-1"} gap-4`}>
            {['Web Design', 'Mobile App Design', 'Collaboration', 'Others'].map((option) => (
              <div key={option} className="flex items-center space-x-2">
                <input type="checkbox" id={option.toLowerCase().replace(' ', '-')} className="rounded bg-gray-800 border-gray-700 text-orange-500 focus:ring-orange-500" />
                <label htmlFor={option.toLowerCase().replace(' ', '-')}>{option}</label>
              </div>
            ))}
          </div>
        </div>

        <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label className="block text-sm font-medium mb-2">Your Budget Range</label>
          <p className="text-sm text-gray-400 mb-2">Slide to indicate your budget range</p>
          <Box sx={{ width: "100%" }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0} // Minimum value of the range
        max={100000} // Maximum value of the range
        step={1000}
        sx={{
          color: '#FFA500', // Custom color for the track and thumb
          '& .MuiSlider-thumb': {
            backgroundColor: '#FFA500', // Thumb color (the handle)
          },
          '& .MuiSlider-rail': {
            color: '#BDBDBD', // Color for the unselected portion (rail)
          },
        }}
      />
    </Box>
        </div>

        <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label htmlFor="message" className="block text-sm font-medium mb-1">Your Message</label>
          <textarea id="message" placeholder="Type here" className="w-full bg-[#262626]  border border-gray-700 rounded-md p-2 text-white" rows={4}></textarea>
        </div>
        <div className='flex justify-center'>

        <button type="submit" className="w-fit bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        </div>

        <div className={`grid ${isDesktop?"grid-cols-1": "md:grid-cols-1"} gap-4 text-sm`}>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span>shivratrimishra@photonsoftwares.com</span>
          </div>
          <div className="flex  items-center  space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <span>+91 8400083557</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span>Get Location</span>
          </div>
        </div>
      </form>
    </div>
        {/* <Box
       
          className="bg-gray1-100 py-16 px-4 mt-3 text-center font-poppins"
          sx={{
            pt: { xs: 1, sm: 2, md: 3 },
            pb: { xs: 2, sm: 4, md: 8 },
            maxWidth: '100%',
            mx: 'auto',
          }}
        >
          <Typography variant="h3" component="h3" className="font-bold  mb-2">
            Contact Us
          </Typography>
          <Typography variant="h6" className="font-semibold mb-2">
            Get In Touch
          </Typography>
          <Typography className="mb-8">
            We’d love to hear from you. Please fill out this form.
          </Typography>
          <Grid container className='mt-2' spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2 }, mx: 'auto' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="First Name"
                      variant="outlined"
                      required
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Last Name"
                      variant="outlined"
                      required
                      placeholder="Last name"
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Email"
                      variant="outlined"
                      required
                      placeholder="you@company.com"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Phone Number"
                      variant="outlined"
                      required
                      placeholder="Mobile No. (Office)"
                    />
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  
                  <Grid className='mt-4' item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{background:"#3A526C"}}
                      sx={{ py: 2 }}
                    >
                      Send Message
                    </Button>
                  </Grid>
              
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      </motion.div>
    </Box>

    </Container>
  );
}

export default Contactus;
