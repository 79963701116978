import { Box, Typography } from '@mui/material'
import React from 'react'
import { motion } from "framer-motion";
import DemoRequest from '../../components/DemoRequest';
const Demoreq = ({isDesktop}) => {
    const variant = {
        initial: {
          // x:100,
          y: 100,
          opacity: 0,
        },
        animate: {
          x: 0,
          opacity: 1,
          y: 0,
          transition: {
            duration: 1,
            staggerChildren: 0.1,
          },
        },
      };
  return (
    <div
      className={`lg:${isDesktop ? " w-3/4" : "w-full"} h-100 p-8 space-y-16`}
    >
      <motion.div
        variants={variant}
        initial="initial"
        whileInView="animate"
        className="space-y-4"
      >
        <Box className="text-left text-darkslateblue  font-text-md-medium ">
        <Typography
              variant="h3"
              className="text-white font-inherit font-bold mt-4 text-center "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Demo Request
            </Typography>
            <Typography className="mb-4 text-center">
            Get in touch with us today and let us help you with any questions or inquiries you may have.</Typography>

            <hr />
            <DemoRequest isDesktop={isDesktop}/>
        </Box>
        </motion.div>
        </div>
  )
}

export default Demoreq