import React from 'react'
import CallMadeIcon from '@mui/icons-material/CallMade';
const Ourworkcard = ({item, index,isDesktop}) => {
  return (
    // <div className="bg-black bg-opacity-50 text-white p-4 sm:p-6 md:p-8">
    //   <div className="grid sm:grid-cols-2 gap-8 md:gap-12">
       
          <div key={index} className="flex flex-col space-y-4 max-w-md">
            <h2 className="text-xl sm:text-2xl font-bold leading-tight">{item.title}</h2>
            <div className="relative w-full ">
              <img
              width={isDesktop?400:300}
                src={item.image}
                alt={item.alt}
                layout="fill"
                objectFit="contain"
                className="rounded-lg"
              />
            </div>
            <h3 className="text-lg sm:text-xl font-semibold">{item.name}</h3>
            <p className="text-gray-400 text-sm">{item.url}</p>
            <p className="text-xs sm:text-sm flex-grow">{item.description}</p>
            <button className="p-2 bg-gray-800 rounded-full hover:bg-gray-700 transition-colors self-start">
              <CallMadeIcon className="w-4 h-4" />
            </button>
          </div>

    //   </div>
    // </div>
  )
}

export default Ourworkcard