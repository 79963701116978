import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ServicesCardsColumns = ({
  className = '',
  number,
  iTStrategyAndArchitecture,
  onServicesCardsButtonsClick,
  isDesktop,
  description
}) => {
  // const handleClick = useCallback(() => {
  //   if (onServicesCardsButtonsClick) {
  //     onServicesCardsButtonsClick();
  //   }
  // }, [onServicesCardsButtonsClick]);

  return (
    <div className='flex justify-center mb-2'>
      <div  className="flex flex-col">
            <div className="flex items-center mb-4">
              <div className="text-[100px] font-Barlow font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#FFA500] to-transparent">{number}</div>
              <h2 className="text-xl md:text-2xl font-bold py-4" style={{
                borderBottom:"1px solid gray"
              }}>{iTStrategyAndArchitecture}</h2>
            </div>
            <p className="text-sm md:text-base text-gray-400 mt-2">{description}</p>
          </div>
    {/* <Card
      className={` bg-black text-white ${className}`}
      sx={{ width: isDesktop ? 350 : '80%', height: 240 }}
    >
      <CardMedia
        // component="text"
        // alt={iTStrategyAndArchitecture}
        // sx={{
        //   height: 150,
        //   objectFit: 'cover',
        //   width: '100%'
        // }}
      />
      <CardContent sx={{ padding: 2 }}>
      <Typography variant="body2" component="div" className='flex justify-center align-items-center gap-3' fontWeight="bold">
      <span className="text-[100px] font-Barlow font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#FFA500] to-transparent">
        {number}
      </span>
        <Typography variant="body2" component="div" fontWeight="bold">
          {iTStrategyAndArchitecture}
        </Typography>
      </Typography>
        <IconButton
          onClick={handleClick}
          color="inherit"
          sx={{ display: 'flex', alignItems: 'center', padding: 0, marginTop: 2 }}
        >
          <Typography variant="body2" className='text-white fw-bold' component="div" sx={{ marginRight: 1 }}>
          We guide businesses through their digital transformation journey by modernizing legacy systems and implementing cutting-edge technologies. Our approach is customer-centric, ensuring that every solution we deliver aligns with your business goals and enhances the overall user experience.
          </Typography>
        </IconButton>
      </CardContent>
    </Card> */}
    </div>
  );
};

ServicesCardsColumns.propTypes = {
  className: PropTypes.string,
  group1000015039: PropTypes.string.isRequired,
  iTStrategyAndArchitecture: PropTypes.string.isRequired,
  onServicesCardsButtonsClick: PropTypes.func,
  isDesktop: PropTypes.bool
};

export default ServicesCardsColumns;
