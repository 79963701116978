import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ServicesCardsColumns from "./CardServices";
import { motion } from "framer-motion";
import { Container, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OurServices = ({ isDesktop }) => {
  // const navigate = useNavigate();
  const [step, setStep]=useState(1);
  // const handleServicesCardsButtonsClick = useCallback(
  //   (stepNumber) => {
  //     setStep(stepNumber);
  //     navigate("/Specialisations", { state: { step: stepNumber } });
  //   },
  //   [navigate, setStep]
  // );

  const variant = {
    initial: { y: 100, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: { duration: 1, staggerChildren: 0.1 },
    },
  };
  const handleStepChange = useCallback((newStep) => {
    setStep(newStep);
  }, []);
 
  const fistecontent=[
    {
      number:"01",
      title:"Digital Transformation",
      description:"We guide businesses through their digital transformation journey by modernizing legacy systems and implementing cutting-edge technologies. Our approach is customer-centric, ensuring that every solution we deliver aligns with your business goals and enhances the overall user experience."
      
    },
    {
      number:"02",
      title:"Oracle Retail Consulting",
      description:"As experts in Oracle Retail solutions, we offer consulting services that help businesses optimize their retail operations. From point-of-sale systems to inventory management, we ensure that your retail technology ecosystem is robust, scalable, and aligned with industry best practices."
      
    },
    {
      number:"03",
      title:"Custom Application Development",
      description:"Photon Software provides end-to-end development services for web and mobile applications. Our team is proficient in a variety of programming languages and frameworks, including Java, Python, React, and PHP. Whether you need a consumer-facing app, a productivity tool, or a loyalty program platform, we deliver solutions that are secure, scalable, and user-friendly."
      
    },
  ]

  return (
    <Container
      id="services"
      className={`${isDesktop ? "h-[650px]" : "fit-content "} overflow-auto`}
    >
      <motion.div
        variants={variant}
        initial="initial"
        whileInView="animate"
        className="space-y-1"
      >
        <Box className="text-left text-darkslateblue  font-text-md-medium ">
          <Box className="text-center text-13xl text-white font-text-md-medium mb-4">
            <Typography
              variant="h3"
              className="text-white font-inherit font-bold mt-4 "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Our Services
            </Typography>
            <Typography className="mb-4">
              Transform your brand with our innovative digital solutions that
              captivate and engage your audience.
            </Typography>
          </Box>
          <Grid
            container
            justifyContent="center"
            style={{
              borderTop: "1px solid gray",
            }}
            spacing={2}
          >
            {step==1 && fistecontent.map((item,index)=>{return(
              <Grid
              key={index}
              item
              xs={12}
              sm={5}
              md={index ==2? 10: 5}
              style={{
                borderRight: `${index==0 && isDesktop?"1px solid gray":""}`,
                borderBottom: "1px solid gray",
              }}
            >
              <ServicesCardsColumns
                isDesktop={isDesktop}
                number={item.number}
                iTStrategyAndArchitecture={item.title}
                description={item.description}
                // onServicesCardsButtonsClick={() =>
                //   // handleServicesCardsButtonsClick(1)
                // }
              />
            </Grid>
            )})}
            
          </Grid>
        </Box>
      </motion.div>
    </Container>
  );
};

OurServices.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default OurServices;
