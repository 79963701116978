import React from 'react'
import {  motion } from 'framer-motion';
import { Container, Row } from 'react-bootstrap';
import { Typography } from '@mui/material';

const variant={
  initial:{
    // x:100,
    y:100,
    opacity:0,
  },
  animate:{
    x:0,
    opacity:1,
    y:0,
    transition:{
      duration:1,
      staggerChildren:0.1
    }
  }
}
function WhyIQ({isDesktop}) {
 
  return (
    <div className={`lg:${isDesktop ? ' w-3/4' : 'w-full'} p-8 space-y-16 ${isDesktop ? "h-[650px]" : "h-fit"}  overflow-hidden`}>
    <Container id="whyIQ" className=''>
    <motion.div 
      variants={variant}
      initial="initial"
      whileInView="animate"
       className="space-y-4">
         <div className="w-full flex flex-col items-center justify-center max-w-full">
              <Row className="mt-2 mb-3">
              <Typography
              variant="h3"
              className="text-white font-inherit font-bold mt-4 "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Why Choose Us
            </Typography>
              </Row>
              <Row className="mt-2 mb-3">
                <h6 className="m-0 text-center font-bold ">Transform your brand with our innovative digital solutions that captivate and engage your audience.</h6>
              </Row>
            </div>
            <div className="bg-black bg-opacity-50 text-white p-4 md:p-8">
      <div className={`grid ${isDesktop?"grid-cols-2 gap-4" :"md:grid-cols-1 md:gap-8 "}  overflow-auto`}>
        <div className="border-b border-gray-700 pb-4 md:pb-8">
          <h2 className="text-[#FFA500] text-xl md:text-2xl font-bold mb-2 md:mb-4">Industry Expertise</h2>
          <p className="text-sm md:text-base">
            With a strong focus on retail, we understand the unique challenges and opportunities in this sector. Our solutions are tailored to meet the specific needs of retailers.
          </p>
        </div>
        <div className="border-b border-gray-700 pb-4 md:pb-8">
          <h2 className="text-[#FFA500] text-xl md:text-2xl font-bold mb-2 md:mb-4">Proven Track Record</h2>
          <p className="text-sm md:text-base">
            We have a history of delivering successful projects that improve operational efficiency, reduce costs, and enhance customer experiences.
          </p>
        </div>
        <div className="border-b md:border-b-0 pb-4 md:pb-0">
          <h2 className="text-[#FFA500] text-xl md:text-2xl font-bold mb-2 md:mb-4">Innovative Solutions</h2>
          <p className="text-sm md:text-base">
            We stay ahead of the curve by adopting the latest technologies and industry best practices, ensuring that our clients benefit from cutting-edge solutions.
          </p>
        </div>
        <div>
          <h2 className="text-[#FFA500] text-xl md:text-2xl font-bold mb-2 md:mb-4">Customer-Centric Approach</h2>
          <p className="text-sm md:text-base">
            We prioritize the needs of our clients and their customers, ensuring that every solution we deliver adds value and drives business success.
          </p>
        </div>
      </div>
    </div>
             
    </motion.div>
    </Container>
    </div>
  )
}

export default WhyIQ
