import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ServicesCardsColumns from "./CardServices";
import { motion } from "framer-motion";
import { Container, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const OurProduct = ({isDesktop}) => {
    // const navigate = useNavigate();
    const [step, setStep]=useState(1);
    // const handleServicesCardsButtonsClick = useCallback(
    //   (stepNumber) => {
    //     setStep(stepNumber);
    //     navigate("/Specialisations", { state: { step: stepNumber } });
    //   },
    //   [navigate, setStep]
    // );
  
    const variant = {
      initial: { y: 100, opacity: 0 },
      animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 1, staggerChildren: 0.1 },
      },
    };
    const handleStepChange = useCallback((newStep) => {
      setStep(newStep);
    }, []);
   
    const fistecontent=[
      {
        number:"01",
        title:"Omni-Channel Retail Solutions",
        description:"We empower retailers to provide a seamless shopping experience across multiple channels. Our omni-channel solutions include API integration for brand management, personalized shopping experiences, and dynamic promotional strategies. By leveraging data and technology, we help retailers engage customers more effectively and drive sales growth."
        
      },
      {
        number:"02",
        title:"In-Store Mobile POS Solutions",
        description:"Photon Software’s in-store mobile POS solutions offer assisted checkout and self-checkout options via QR codes. These solutions are designed to reduce friction in the shopping process, increase customer satisfaction, and optimize store operations."
        
      },
      {
        number:"03",
        title:"Loyalty, Lead and CRM Solution",
        description:"Photon Softwares offers various solutions in these areas, particularly focused on digital transformation in retail. Photon Softwares provides CRM solutions integrated with other systems like POS, ERP, and eCommerce platforms. This ensures a seamless flow of customer information aoss different channels and improves customer engagement."
        
      },
    ]
    const second=[
      {
        number:"04",
        title:"Photon -  Smart Store",
        description:"Photon Smart store application aims to improve Instore experience of the customers. The functionalities are designed to assist instore sales associates to resolve all the customer queries quickly & perform all backend related activity(Store Inventory Management) Customer Order Deliver, Click and Collect related activity using our APP"
        
      },
      {
        number:"05",
        title:"INVENTORY As A SERVICE",
        description:"Inventory is crucial information/data which enables the store to carry out selling business. Stores are normally bound to one single status inventory system which restricts the business opportunity."
        
      },
      {
        number:"06",
        title:"Photon - Couponing Solution",
        description:"Our unique solution helps retailers to bring their unique business model related to coupon generation/redemption and use it as gift or loyalty rewards which can be enabled in our coupon service on the"
        
      },
    ]
    const third=[
      {
        number:"07",
        title:"Retail and Health Care Solution",
        description:"Some stores work on personalised service where making appointments are important. Our framework provides a user-friendly appointment management system as a service so"
        
      },
      {
        number:"08",
        title:"Clientelling",
        description:"Photon Softwares provides solutions like personalized shopping experiences, loyalty integration, and appointment systems, which are components often associated with clientelling. These services aim to enhance customer engagement by using data and technology to tailor interactions and offers to individual customers."
        
      },
      {
        number:"09",
        title:"Promotion Engine",
        description:"Providing personalized offers in real-time based on customer behavior. Integration with loyalty programs to offer promotions tied to customer loyalty status.  Ensuring that promotions are consistent across both online and offline channels. Setting up complex rules for applying discounts and offers automatically."
        
      },
    ]
  
    const nextstep=()=>{
      setStep((prevstep)=> prevstep +1)
    }
    const prvestep=()=>{
      setStep((prevstep)=> prevstep -1)
    }
  
  
  
    return (
      <Container
        id="services"
        className={`${isDesktop ? "h-[650px]" : "fit-content "} overflow-auto`}
      >
        <motion.div
          variants={variant}
          initial="initial"
          whileInView="animate"
          className="space-y-1"
        >
          <Box className="text-left text-darkslateblue  font-text-md-medium ">
            <Box className="text-center text-13xl text-white font-text-md-medium mb-4">
              <Typography
                variant="h3"
                className="text-white font-inherit font-bold mt-4 "
              >
                {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
                {/* </div>   */}
                Our Product
              </Typography>
              <Typography className="mb-4">
                Transform your brand with our innovative digital solutions that
                captivate and engage your audience.
              </Typography>
              <div className={`flex flex-wrap justify-center items-center ${isDesktop? "gap-24":"gap-4"}`}>
              <ArrowBackIcon className="cursor-pointer" onClick={()=>{
                if(step >1){
                  prvestep()
                }
              }}/>
    {[1, 2, 3].map((item) => (
      <div
        key={item}
        onClick={() => handleStepChange(item)}
        className={`h-1 ${isDesktop? "w-36":"w-10"} cursor-pointer ${
          step === item ? 'bg-white' : 'bg-gray-600'
        }`}
      />
    ))}
    <ArrowForwardIcon className="cursor-pointer" onClick={()=>{
                if(step <3){
                  nextstep()
                }
              }} />
  </div>
            </Box>
            <Grid
              container
              justifyContent="center"
              style={{
                borderTop: "1px solid gray",
              }}
              spacing={2}
            >
              {step==1 && fistecontent.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
               {step ==2 &&second.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
               {step ==3 &&third.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
              
            </Grid>
          </Box>
        </motion.div>
      </Container>
    );
  };
export default OurProduct