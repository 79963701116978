import React, { useCallback, useState } from "react";

import { motion } from "framer-motion";
import Section from "./Section";
import { Box, Grid, Typography } from "@mui/material";
import Ourworkcard from "../../components/Ourworkcard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const variant = {
  initial: {
    // x:100,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};
function OurWork({ isDesktop }) {
  const [step, setStep] = useState(1);
  const handleStepChange = useCallback((newStep) => {
    setStep(newStep);
  }, []);

  const firstContent = [
    {
      title: "E-Commerce Platform for Farmer",
      image: "/IBshoppy.png?height=300&width=400",
      alt: "IB Shoppy platform screenshot",
      name: "IB Shoppy",
      url: "https://www.ibshoppy.com",
      description:
        "We developed a visually stunning and user-friendly e-commerce platform for IB Shoppy...",
    },
    {
      title: "Mobile App for Food Delivery Service",
      image: "/Annpurnaimg.png?height=300&width=400",
      alt: "Annapurna app screenshot",
      name: "Annapurna",
      url: "https://www.Annapurna.com",
      description:
        "Annapurna approached us to create a mobile app that streamlined their food delivery service...",
    },
  ];
  const secondContent = [
    {
      title: "Mobile App for Food Delivery Service",
      image: "/food4you.png?height=300&width=400",
      alt: "IB Shoppy platform screenshot",
      name: "Food4You",
      url: "https:/www.Food4You.shop",
      description:
        "Food4You  approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
    },
    {
      title: " Mobile App for Fashion E-Commerce App ",
      image: "/fashion.png?height=300&width=400",
      alt: "Annapurna app screenshot",
      name: "Fashion E-Commerce App",
      url: "https://www.Fashion.com",
      description:
        "Download & shop online for fashionable clothing trends on the fashion app. Our online app covers everything from fashionable clothes, beauty & skincare to trending home products. Fashion E-Commerce App is your one-stop lifestyle online shop. Explore the large variety of beauty, personal care & fashion products.",
    },
  ];
  const thirdContent = [
    {
      title: "Mobile App for Empowering Delivery ",
      image: "/Deliveryapp.png?height=300&width=400",
      alt: "IB Shoppy platform screenshot",
      name: "NanoOmni Delivery",
      url: "htttps:/www.NanoOmni Delivery.com",
      description:
        "Designing a delivery app based on pin code with a focus on empowering delivery personnel for efficient delivery is a great idea. Below is a basic outline of the key features and functionalities you might consider incorporating into your Nanoomni Delivery App.",
    },
    {
      title: " Mobile App for Mahaveer Mart ",
      image: "/mahveermart.png?height=300&width=400",
      alt: "Annapurna app screenshot",
      name: "Mahaveer Mart",
      url: "www.Mahaveer Mart.com",
      description:
        "MahaveerMart Customer delivers a world-class experience by offering comprehensive solutions for retailers aiming to enhance customer satisfaction. Our platform specializes in streamlined order management, fostering loyalty, executing promotions, managing pricing structures, and presenting detailed dashboards reflecting real-time order statuses.",
    },
  ];
  const FourthContent = [
    {
      title: "Mobile App for NanoOmni ShopEase",
      image: "/nonoOmni.png?height=300&width=400",
      alt: "IB Shoppy platform screenshot",
      name: "NanoOmni ShopEase",
      url: "https://posprd.photonsoftwares.com/",
      description:
        "NanoOmni Customer delivers a world-class experience by offering comprehensive solutions for retailers aiming to enhance customer satisfaction. Our platform specializes in streamlined order management, fostering loyalty, executing promotions, managing pricing structures, and presenting detailed dashboards reflecting real-time order statuses.",
    },
    {
      title: "Mobile App for GrocerySpot ",
      image: "/Grocryspot.png?height=300&width=400",
      alt: "Annapurna app screenshot",
      name: "GrocerySpot",
      url: "www.Mahaveer Mart.com",
      description:
        "GrocrySpot is an innovative online grocery ecommerce app that revolutionizes the way customers shop for essentials. With a user-friendly interface and seamless navigation, GrocrySpot allows users to effortlessly browse through a wide range of products, from fresh produce to pantry staples and household items.",
    },
  ];
  const FiveContent = [
    {
      title: "Point od sale (Pos) for Fashion store",
      image: "/poinofsale.png?height=300&width=400",
      alt: "IB Shoppy platform screenshot",
      name: "Fashion store (POS)",
      url: "https:/www.Fashion store (POS).com",
      description:
        "A Point of Sale (POS) system for a fashion store is a comprehensive software and hardware solution designed to facilitate and streamline the sales process, inventory management, and customer interaction. This system serves as the central hub for managing in-store transactions, tracking inventory, and generating reports, making it an essential tool for running a successful retail fashion business.",
    },
    {
      title: "Fashion store  (Mobile POS ) ",
      image: "/fashionstore.png?height=300&width=400",
      alt: "Annapurna app screenshot",
      name: "Fashion store  (Mobile POS )",
      url: "htttps:/www.Fashion store  (Mobile POS ).com",
      description:
        "A Mobile Point of Sale (mPOS) system for a fashion store is a compact and versatile tool that allows sales transactions and retail operations to be managed via a mobile app. This system combines the functionality of a traditional POS system with the convenience and mobility of a smartphone or tablet, making it ideal for modern, dynamic retail environments.",
    },
  ];

  const nextstep=()=>{
    setStep((prevstep)=> prevstep +1)
  }
  const prvestep=()=>{
    setStep((prevstep)=> prevstep -1)
  }

  return (
    <div
      className={`lg:${isDesktop ? " w-3/4" : "w-full"} h-100 p-8 space-y-16`}
    >
      <motion.div
        variants={variant}
        initial="initial"
        whileInView="animate"
        className="space-y-4"
      >
        <Box className="text-left text-darkslateblue  font-text-md-medium ">
          <Box className="text-center text-13xl text-white font-text-md-medium mb-4">
            <Typography
              variant="h3"
              className="text-white font-inherit font-bold "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Our Work
            </Typography>
            <Typography className="mb-4">
              Discover a portfolio of visually stunning and strategically
              crafted digital projects that showcase our creativity and
              expertise.
            </Typography>
            <div
              className={`flex flex-wrap justify-center items-center ${
                isDesktop ? "gap-11" : "gap-1"
              }`}
            >
            <ArrowBackIcon className="cursor-pointer" onClick={()=>{
                if(step >1){
                  prvestep()
                }
              }}/>
              {[1, 2, 3, 4, 5].map((item) => (
                <div
                  key={item}
                  onClick={() => handleStepChange(item)}
                  className={`h-1 ${
                    isDesktop ? "w-24" : "w-8"
                  } cursor-pointer ${
                    step === item ? "bg-white" : "bg-gray-600"
                  }`}
                />
              ))}
              <ArrowForwardIcon className="cursor-pointer" onClick={()=>{
                if(step <5){
                  nextstep()
                }
              }} />
            </div>
          </Box>
          <div className={`flex ${isDesktop ? "flex-row" : "flex-col"}`}>
            {step==1&&  firstContent.map((item, index) => (
              <Ourworkcard key={index} isDesktop={isDesktop} item={item} />
            ))}
            {step==2&&  secondContent.map((item, index) => (
              <Ourworkcard key={index} isDesktop={isDesktop} item={item} />
            ))}
            {step==3&&  thirdContent.map((item, index) => (
              <Ourworkcard key={index} isDesktop={isDesktop} item={item} />
            ))}
            {step==4&&  FourthContent.map((item, index) => (
              <Ourworkcard key={index} isDesktop={isDesktop} item={item} />
            ))}
            {step==5&&  FiveContent.map((item, index) => (
              <Ourworkcard key={index} isDesktop={isDesktop} item={item} />
            ))}
          </div>
          {/* <Grid
              container
              justifyContent="center"
              style={{
                borderTop: "1px solid gray",
              }}
              spacing={2}
            >
              {step==1 && fistecontent.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
               {step ==2 &&second.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
               {step ==3 &&third.map((item,index)=>{return(
                <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={index ==2? 10: 5}
                style={{
                  borderRight: `${index==0&& isDesktop?"1px solid gray":""}`,
                  borderBottom: "1px solid gray",
                }}
              >
                <ServicesCardsColumns
                  isDesktop={isDesktop}
                  number={item.number}
                  iTStrategyAndArchitecture={item.title}
                  description={item.description}
                  // onServicesCardsButtonsClick={() =>
                  //   // handleServicesCardsButtonsClick(1)
                  // }
                />
              </Grid>
              )})}
              
            </Grid> */}
        </Box>
      </motion.div>
    </div>
  );
}

export default OurWork;
