import React, { useEffect, useState } from 'react'
import {  motion } from 'framer-motion';
import Header from '../../components/SubComponent/Header';
import TagButton from '../../components/SubComponent/TagButton';
import ActionButton from '../../components/SubComponent/ActionButton';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import TextTransition, { presets } from 'react-text-transition';

const variant={
    initial:{
      // x:100,
      y:100,
      opacity:0,
    },
    animate:{
      x:0,
      opacity:1,
      y:0,
      transition:{
        duration:1,
        staggerChildren:0.1
      }
    }
  }
  const targetAudiences = ["Startups", "Enterprise leaders", "Retailer's", "E-commerce"]
function Home({isDesktop}) {
  const navigate = useNavigate(); // Create a navigate function
  const handleNavigation = (path) => {
    navigate(path);
  }
  const [text, setText] = useState('ERP Implementation Odoo Oracle Retail NetSuit SAP');
  const texts = ['ERP Implementation Odoo Oracle Retail NetSuit SAP', 'Ecommerce Portal Custom Ecommerce Channel Development', 'Mobile App Development More than 60 App live on Play Store', 'Custom Application Development','AgiTech ERP Solution' ,'Point of Sales Fashion, Grocery , Electronics','Loyalty Solution for Retailers and Non Retailers'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setText((prevText) => {
        const currentIndex = texts.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % texts.length;
        return texts[nextIndex];
      });
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
  return (
    <div  className={` lg:${isDesktop ? ' w-full flex items-center justify-center bg-opacity-50' : 'w-full'} p-8 space-y-16 ${isDesktop ? "h-[650px]" : "h-[850px]"}`}
    style={{backgroundImage:"url('/bghome.jpg')",
      backgroundSize: "cover", /* Ensures the image covers the entire background */
      backgroundPosition: "center", /* Centers the background image */
      backgroundRepeat:" no-repeat", /* Prevents the image from repeating */
      backgroundAttachment: "fixed",
      }}
      >
        

   
    <motion.div 
      variants={variant}
      initial="initial"
      whileInView="animate"
       className="space-y-4">
      <main className={`flex relative flex-col justify-between  max-md:px-5 ${isDesktop?'gap-1':'gap-[40px]'}`}
      >
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/152344d04d96c613a53a3ccc9592c2970b839f9f104f7f16c12facaa6d8f25cf?placeholderIfAbsent=true&apiKey=8657b43bd062413b86edd82c7ffa86b6"
        alt=""
        className="object-cover absolute inset-0 size-full"
      /> */}
      {/* <Header /> */}
      {/* <section className="flex relative flex-col justify-center w-full max-md:max-w-full">
        <h1 className="text-7xl font-semibold text-center text-white max-md:max-w-full max-md:text-4xl">
          Empowering Digital Transformation in Retail That Works for You
        </h1>
        <div className="flex flex-wrap gap-1.5 items-center self-center px-10 py-6 mt-10 text-2xl rounded-xl bg-neutral-800 bg-opacity-20 text-neutral-400 max-md:px-5 max-md:max-w-full">
          <span className="self-stretch my-auto">For</span>
          {tags.map((tag, index) => (
            <React.Fragment key={tag}>
              <TagButton>{tag}</TagButton>
              {index < tags.length - 1 && <span className="self-stretch my-auto">,</span>}
            </React.Fragment>
          ))}
          <span className="self-stretch my-auto">and</span>
        </div>
        <div className="flex gap-3.5 items-start self-center mt-12 text-lg font-medium max-md:mt-10">
          <ActionButton variant="outline">Our Products</ActionButton>
          <ActionButton variant="primary">Free Demo Request</ActionButton>
        </div>
      </section> */}
      <p className={`text-10xl font-bold text-start text-wrap text-[#FEDF25] `}>

      <TextTransition
            springConfig={presets.wobbly}
            className="big"
            delay={300}
            // inline
          >
            {text}
          </TextTransition>

      </p>
      {/* <div className=" w-full max-w-2xl mx-auto  text-white p-6 rounded-lg shadow-lg bg-black bg-opacity-50" >
      <div className="space-y-4 mb-6">
        <h2 className="text-lg font-semibold">For:</h2>
        <div className={`flex  ${isDesktop?"flex-row":"flex-col"} space-y-2 gap-2 `}>
          {targetAudiences.map((audience) => (
            <button
              key={audience}
              className="py-2 px-2 m-0 bg-[#262626] hover:bg-gray-700 rounded text-left transition duration-300 text-white"
            >
              {audience}
            </button>
          ))}
        </div>
      </div>
      
    </div> */}
    <strong className='text-xl '>Whatever your unique idea or needs, we’ve got the tools and know how to build your custom solution from discovery to deployment.</strong>
    <div className="flex justify-around mt-3 space-y-3">
        <Link
        to='/product' className="max-w-fit border no-underline text-white py-3 px-4 bg-[#262626] hover:bg-gray-700 rounded transition duration-300">
          Our Products
        </Link>
        {!isDesktop &&<button onClick={() => handleNavigation('/demo')} className="max-w-fit py-3 px-4 m-0 bg-[#FFA500] text-black rounded font-semibold transition duration-300">
          Free Demo Request
        </button>}
      </div>
    </main>
</motion.div>
    </div>
  )
}

export default Home
